// NOTE could get this from an outside source since it changes slightly over time
// export const BLOCK_RATE_SECONDS = 0.9; //FTM
export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    OHM_ADDRESS: "0xA4755BC6480102cfA51DC2F3BA6062Ecc6ac8a74", //
    OLD_OHM_ADDRESS: "0x986d88736CC8c18069B75a3023EC3880f3b20562", //
    SOHM_ADDRESS: "0xa63d5F3EE5C21786455AB92Bda815169853a1093",
    STAKING_ADDRESS: "0x915f4C27C8e73794B9cb10101f6F3cC1Eb46cef7",
    STAKING_HELPER_ADDRESS: "0x57A7B25b48f6b61f41D40188D8453750A295CDb5",
    DISTRIBUTOR_ADDRESS: "0x520eb1D994fefafe3c043eD1D45c719015b87e5c",
    BONDINGCALC_ADDRESS: "0x5Fa924B8FC9840060E269EC1Bf3357f63C5D83F4",
    TREASURY_ADDRESS: "0x41ecc222aCA69390bdE8b224BC882044D04C65c7",
    USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955", //
    DAO_ADDRESS: '0xA067054d7Ef642d4c0Da0fd7D2D6ab37DbC2A4FD',
    LP_ADDRESS: '0xc04c0a86EDb967aa6E82d970992A0B8F0f04f917',
    REDEEM_HELPER_ADDRESS: "0xEC7b22d3f84cC14122F31C2FC0B37DA91753Bfd3",
    ALPHA_TOKEN_ADDRESS: '0xe86726506B524c3F60e2b4783123A20266BABf89', //
    MIGRATION_ADDRESS:'0x4B80F688161b4880b4Fe001b406C4017DD0Ea80c',
    INVITE_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    TEAM_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    TEAM_AMOUNT_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    LOCK_ADDRESS:'0x8949c7E456fc06FbB36a887D9646A62d30a79fb1',
  },
  97: {
    OHM_ADDRESS: "0x16fb7c14adB7fB4dF4A807E0e2FEf1FFE18fD8f5", //
    OLD_OHM_ADDRESS: "0xdE5a05d58EC30171732C1d0B9832442158AEAC54", //
    SOHM_ADDRESS: "0x7620077BfAdcAAD29cAFf762A088B08A478DFAF0",
    STAKING_ADDRESS: "0x4ce06Fd3E80EA3d62196c90Bab6E7A792Fc25C87",
    STAKING_HELPER_ADDRESS: "0xb36f954903c31b32fd3a87711cfa1f2399bd0b71",
    DISTRIBUTOR_ADDRESS: "0x6847748Fc82A0Cf7109389c125462D246ea7A7e5",
    BONDINGCALC_ADDRESS: "0xfA86d01c1D4f3FB1e29027cABA0D8fBc58aB5664",
    TREASURY_ADDRESS: "0x5506ca73c5503AEc8fe49D93bb8a2379a3D949Dd",
    USDT_ADDRESS: "0x3937E0C6d31b4D6c0e2A246Fe168D6FF07cf6DB9", //
    DAO_ADDRESS: '0xA58958e2E6bb289E20ee4e73d8BF3909f99aDbCe',
    LP_ADDRESS: '0xB34CCd9fEF47AF8ca9f758c6236F199f898DD73b',
    REDEEM_HELPER_ADDRESS: "0xc0ac31bc0a4C79C38f288A389e0432f78A2C54c3",
    ALPHA_TOKEN_ADDRESS: '0xe86726506B524c3F60e2b4783123A20266BABf89', //
    MIGRATION_ADDRESS:'0x4c84362007b0013DF2Bd50B3a4D0B5c2ED591b53',
    INVITE_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    TEAM_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    TEAM_AMOUNT_ADDRESS:'0x32237d4498Ff1d604EaBFCA905bF5Cb866e66946',
    LOCK_ADDRESS:'0x057864874b2FA7E52Eba23900F25922fBf65BAb1',
  }
};
