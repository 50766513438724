import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';

import { useWeb3Context } from '../../hooks/web3Context'
import { openSuccessNotification } from '../../utils/tip.js';
import { getQueryString } from '../../utils'

import copyImg from '../../assets/images/copy.png';
import earthImg from '../../assets/images/earth.png';
import logout from '../../assets/images/logout.png';
import metamaskImg from '../../assets/images/metamask.png';
import point from '../../assets/images/point.png';
import closeImg from '../../assets/images/close.png';

import './wallet.scss'

const link = window.location.origin + '/dashboard'

export default function Wallet({ isShowWallet, setShowWallet }) {
	const {pathname} = useLocation()
	const [inviteAddress, setInviteAddress] = useState('')
	const { address, chainID, disconnect } = useWeb3Context()
	const isVisible = isShowWallet

	useEffect(() => {
		const address = getQueryString('address')
		if(address) {
			setInviteAddress(address)
		}
	}, [pathname])

	const copyAddress = () => {
		copy(address);
		openSuccessNotification('Copy Succeeded');
	}

	return (
		<Dialog
			// title=''
			// centered={true}
			// closable={false}
      // contentStyle={{width: '400px'}}
			open={isVisible}
			onClose={() => setShowWallet()}>
			<div className="walletInfo-container">
				<div className="flex flex-justify-content-between flex-align-items-center">
					<div className="font-14 font-weight-b" style={{ color: '#22fda4' }}>Your Wallet</div>
					<img className="clsoe-icon" src={closeImg} alt="" onClick={() => setShowWallet()} />
				</div>
				<div className="walletInfo">
					<div className="box flex flex-justify-content-between">
						<div className="flex flex-column flex-align-items-center box-l">
							<img src={metamaskImg} alt="" style={{ width: 50, height: 46 }} />
							<span style={{ fontSize: 12, marginTop: 17 }}>MetaMask</span>
							<div style={{ fontSize: 12, marginTop: 14, color: '#26D483' }}>
								<img className="mr-8" src={point} alt="" style={{ width: 10, height: 10 }} />
								<span>Connected</span>
							</div>
						</div>

						<div className="flex flex-column flex-justify-content-center box-r">
							<div style={{ wordBreak: 'break-all' }}>{address}</div>
							<div className="flex mt-20 operations">
								<div className="operationItem flex flex-align-items-center"
									onClick={copyAddress}>
									<div className="mr-8"><img src={copyImg} alt="" style={{ width: 15, height: 17 }} /></div>
									<div className="operate-text">Copy Address</div>
								</div>
								<a className="operationItem flex flex-align-items-center viewInExplorer"
									href={`https://bscscan.com/address/${address}`} target="_blank">
									<div className="mr-8"><img src={earthImg} alt="" style={{ width: 15, height: 17 }} /></div>
									<div className="operate-text">View in Explorer</div>
								</a>
							</div>
						</div>
					</div>
					{/* <div className="invite-container">
						<div className="flex flex-justify-cotent-start flex-align-items-center invite-link">
							<span className="mr-10">{`${link}?address=${address}`}</span>
							<img src={copyImg} alt="" onClick={() => copyLink('')} />
						</div>
					</div> */}
					
					{/* {
						superiorAddresses?
						<div className="invite-container">
							<div className="flex flex-justify-cotent-start flex-align-items-center invite-link">
								<span className="mr-10">{superiorAddresses}</span>
								<img src={copyImg} alt="" onClick={() => copyLink('super')} />
							</div>
						</div> : null
					} */}

				</div>
				<div className="flex flex-justify-content-between flex-align-items-center mt-20">
					<Button onClick={disconnect} className="logOutBtn" block={true}>
						<div className="flex flex-justify-content-between flex-align-items-center">
							<img src={logout} alt="" style={{ width: 15, height: 15, marginRight: 4 }} />
							<span>Logout</span>
						</div>
					</Button>
					{/* todo */}
					{/* <Button onClick={disconnect} className="buyBtn" block={true} disabled>
						<span>Buy IND</span>
					</Button> */}
				</div>
			</div>
		</Dialog>
	)
}