import { AppBar, Toolbar, Box, Button, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import Menu from 'src/assets/menu-icon.png'
import Logo from '../../assets/logo.png'
import SwitchLang from "./SwitchLang.jsx";
import OhmMenu from "./OhmMenu.jsx";
import ConnectWallet from "./ConnectWallet.jsx";

// import "./topbar.scss";
// import "./topbar.app.scss";

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "transparent",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function AppTopBar({ theme, handleDrawerToggle }) {
  const classes = useStyles();
  const isVerySmallScreen = useMediaQuery("(max-width: 800px)");

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar flex flex-justify-content-between flex-align-items-center">
        {isVerySmallScreen ? null : <img className="logo" src={Logo} alt="" />}

        <Button
          id="hamburger"
          aria-label="open drawer"
          edge="start"
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          style={{background: 'transparent'}}
        >
          {/* <SvgIcon component={MenuIcon} /> */}
          <img src={Menu} alt="" style={{width: 38, height: 38}} />
        </Button>

        <Box display="flex" alignItems="center">
          <SwitchLang />
          <OhmMenu />
          <ConnectWallet />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppTopBar;
