import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Social from "./Social";

import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon, Icon } from "@material-ui/core";
import "./sidebar.scss";

import { useTranslation } from 'react-i18next';

function NavContent({ theme, showPerformance }) {
  const { t } = useTranslation();
  const [isActive] = useState();
	const {pathname} = useLocation()
  const history = useHistory()
  const address = useAddress();

  const [sidbarRoute, setSidbarRoute] = useState('')

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");

    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("ido") >= 0 && page === "ido") {
      return true;
    }
    if (currentPath.indexOf("calculator") >= 0 && page === "calculator") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    if (currentPath.indexOf("governance") >= 0 && page === "governance") {
      return true;
    }
    if (currentPath.indexOf("redeem") >= 0 && page === "redeem") {
      return true;
    }
    if (currentPath.indexOf("tokenSwap") >= 0 && page === "tokenSwap") {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">

          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">

              <Link
                component={NavLink}
                id="dash-nav"
                to="/dashboard"
                isActive={(match, location) => {
                  setSidbarRoute(location.pathname)
                  return checkPage(match, location, "dashboard");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.dashboard')}
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="stake-nav"
                to="/stake"
                isActive={(match, location) => {
                  setSidbarRoute(location.pathname)
                  return checkPage(match, location, "stake");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.stake')}
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="calculator-nav"
                to="/calculator"
                isActive={(match, location) => {
                  return checkPage(match, location, "calculator");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.calculator')}
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/bond"
                isActive={(match, location) => {
                  return checkPage(match, location, "bond");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.bond')}
                  </Typography>
                </div>
              </Link>
              
              {/* {
                address && (nodeAddress || gcAddress) && showPerformance ?
                <Link
                component={NavLink}
                id="genesis-nav"
                to="/genesis"
                isActive={(match, location) => {
                  return checkPage(match, location, "genesis");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    DC&GC
                  </Typography>
                </div>
              </Link> : null} */}

              <Link
                component={NavLink}
                id="invite-nav"
                to="/invite"
                isActive={(match, location) => {
                  return checkPage(match, location, "invite");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.invite')}
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="lock-nav"
                to="/lock"
                isActive={(match, location) => {
                  return checkPage(match, location, "lock");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.lock')}
                  </Typography>
                </div>
              </Link>

              {/* <Link href={'https://docs.indao.money/'} target="_blank">
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className="Arial-Black">
                    {t('menu.docs')}
                  </Typography>
                </div>
              </Link> */}

            </div>
          </div>
        </div>
      </Box>
    </Paper>
  );
}

export default NavContent;
