import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import "./switchLang.scss";

import China from '../../assets/china.png'
import UK from '../../assets/uk.png'
import France from '../../assets/france.png'
import KR from '../../assets/kr.png'
import Spain from '../../assets/spain.png'

const langImg = {
  'en': UK,
  'hk': China,
  'fr': France,
  'kr': KR,
  'sp': Spain,
}

function SwitchLang() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "lang-popper";

  const switchLanguage = (target) => {
    i18n.changeLanguage(target)
  }

  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="ohm-menu-button-hover"
    >
      <Button id="ohm-menu-button" size="large" variant="contained" color="secondary" title="IND" aria-describedby={id}>
        <img src={langImg[currentLang] || UK} alt="" style={{width: 30, height: 20}} />
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="ohm-menu" elevation={1}>
                  <Box className="add-tokens">
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={() => switchLanguage('en')}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={UK} alt="" style={{width: 30, height: 20}}/>
                      </div>
                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => switchLanguage('fr')}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={France} alt="" style={{width: 30, height: 20}}/>
                      </div>
                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => switchLanguage('kr')}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={KR} alt="" style={{width: 30, height: 20}}/>
                      </div>
                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => switchLanguage('hk')}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={China} alt="" style={{width: 30, height: 20}}/>
                      </div>
                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => switchLanguage('sp')}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={Spain} alt="" style={{width: 30, height: 20}}/>
                      </div>
                    </Button>
                  </Box>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}

export default SwitchLang;
