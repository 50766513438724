import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useWeb3Context } from "src/hooks/web3Context"

import Wallet from '../Wallet/wallet'

export default function ConnectWallet() {
  const { address, connect, connected, web3 } = useWeb3Context()
  const [isConnected, setConnected] = useState(connected)
	const [isShowWallet,setShowWallet] = useState(false)
  let buttonText = 'Connect Wallet'
  let clickFunc = connect

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions
  })

  if (isConnected) {
    buttonText = address.substring(0, 6) + '...'
    clickFunc = () => setShowWallet(true)
  }

  if (pendingTransactions && pendingTransactions.length > 0) {
    buttonText = "In progress"
    clickFunc = () => {}
  }

  useEffect(() => {
    setConnected(connected)
  }, [web3, connected])

  return (
    <>
      <div className="connect-wallet menu-item flex flex-align-items-center" onClick={clickFunc}>
        {buttonText}
      </div>

      <Wallet isShowWallet={isShowWallet} setShowWallet={setShowWallet} />
    </>
  )
}
