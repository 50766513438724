import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { AppBar, Toolbar, Link, Button, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Logo from '../../assets/logo.png'
import SwitchLang from "./SwitchLang.jsx";
import OhmMenu from "./OhmMenu.jsx";
import ConnectWallet from './ConnectWallet'

import "./topbar.scss";
import "./topbar.app.scss";

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    // justifyContent: "flex-end",
    // alignItems: "flex-end",
    background: "transparent",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function TopBar() {
  const history = useHistory()
  const {pathname} = useLocation()
  const classes = useStyles();
  const smallScreen = useMediaQuery("(max-width: 500px)");
  const [isInner, setIsInner] = useState(false)

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");

    if (currentPath.indexOf("about") >= 0 && page === "about") {
      return true;
    }
    if (currentPath.indexOf("bond") >= 0 && page === "bond") {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    if(pathname === '/' || pathname === '/about') {
      setIsInner(false)
    } else {
      setIsInner(true)
    }
  }, [pathname])

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar">
        {smallScreen ? null : <img className="logo" src={Logo} alt="" onClick={() => history.push('/')} />}

        <div className="top-menu flex flex-align-items-center">
          {/* <div className="menu-item">
            <Link
              component={NavLink}
              to="/about"
              isActive={(match, location) => {
                return checkPage(match, location, "about");
              }}
              className="about"
            >
              About
            </Link>
          </div> */}
          <div className="menu-item">
            <Link
              component={NavLink}
              to="/stake"
              isActive={(match, location) => {
                return checkPage(match, location, "stake");
              }}
              className="about"
            >
              Stake
            </Link>
          </div>
          <div className="menu-item">
            <Link
              component={NavLink}
              to="/bond"
              isActive={(match, location) => {
                return checkPage(match, location, "bond");
              }}
              className="bond"
            >
              Bond
            </Link>
          </div>
          <div className="menu-item">
            <Link
              href={'https://docs.indao.money/'} target="_blank"
              className="faq"
            >
              Docs
            </Link>
          </div>
          <SwitchLang />
          <OhmMenu />
          {!smallScreen
            ? 
            isInner ?  <ConnectWallet /> : <div className="menu-item menu-btn" onClick={() => history.push('/bond')}>Open App</div>
            : null
          }
        </div>

        {/* {smallScreen ?
          isInner ? <div style={{ marginTop: 24 }}><ConnectWallet /></div> :
          <div className="flex flex-justify-content-center" style={{ width: '100%' }}>
            <div className="menu-btn" onClick={() => history.push('/bond')}>Open App</div>
          </div> : null} */}

      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
