import 'antd/dist/antd.css';

import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";

import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails, loadGovernanceDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, calculateUserSuperBondDetails } from "./slices/AccountSlice";

import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import AppTopBar from "./components/TopBar/AppTopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import LoadingSplash from "./components/Loading/LoadingSplash";
import Messages from "./components/Messages/Messages";
import NotFound from "./views/404/NotFound";
import { ethers } from "ethers"
import { dark as darkTheme } from "./themes/dark.js";
import i18n from './react-i18next-config'

import "./style.scss";

const DEBUG = false;
const Index = lazy(() => import('./views/Index/Index'));
const About = lazy(() => import('./views/About/About'));
const InnerBond = lazy(() => import('./views/InnerBond/InnerBond'));

const Bond = lazy(() => import('./views/InnerBond/Bond/index'));
const Calculator = lazy(() => import('./views/InnerBond/Calculator/index'));
const Dashboard = lazy(() => import('./views/InnerBond/Dashboard/index'));
const Invite = lazy(() => import('./views/InnerBond/Invite/index'));
const InviteDetail = lazy(() => import('./views/InnerBond/InviteDetail/index'));
const GC = lazy(() => import('./views/InnerBond/GC/GC'));
const Stake = lazy(() => import('./views/InnerBond/Stake/index'));
const LockPage = lazy(() => import('./views/InnerBond/Lock/Lock'));

if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

const drawerWidth = 280;
const transitionDuration = 969;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: transitionDuration,
    }),
    height: "100%",
    overflow: "auto",
    // marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginLeft: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function App() {
  // useGoogleAnalytics();
  const dispatch = useDispatch();
  const [theme, toggleTheme] = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [showPerformance, setShowPerformance] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 958px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const address = useAddress();

  const [walletChecked, setWalletChecked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isInner, setIsInner] = useState(false);
  const { bonds } = useBonds();

  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }
    if (whichDetails === "account" && address && connected && isLoad) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      dispatch(loadGovernanceDetails({ networkID: chainID, provider: loadProvider }));
      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: null, provider: loadProvider, networkID: chainID }));
      });
      setIsLoad(true)
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      // dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(calculateUserSuperBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
      });
    },
    [connected],
  );

  const switchPerformance = () => {
    setShowPerformance(!showPerformance)
  }

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    storeQueryParameters();
  }, []);

  useEffect(() => {
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    if (connected && isLoad) {
      loadDetails("account");
    }
  }, [connected, isLoad]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  let themeMode = darkTheme;

  useEffect(() => {
    themeMode = darkTheme;
  }, [theme]);

  useEffect(() => {
    if(location.pathname === '/' || location.pathname === '/about') {
      setIsInner(false)
    } else {
      setIsInner(true)
    }
    if (isSidebarExpanded) handleSidebarClose();
  }, [location]);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <div className={`app ${isSmallerScreen ? (isInner ? 'app-inner-bg' : 'app-out-bg') : (isInner ? 'inner-bg' : 'out-bg')} ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
        <Messages />
        {
          isSmallScreen
          ? 
            <AppTopBar
              handleDrawerToggle={handleDrawerToggle}
            />
          :
            <TopBar
              showPerformance={showPerformance}
              theme={theme}
              switchPerformance={switchPerformance}
              toggleTheme={toggleTheme}
              handleDrawerToggle={handleDrawerToggle}
            />
        }
        <nav className={classes.drawer}>
          <Hidden mdUp >
            <NavDrawer theme={theme} showPerformance={showPerformance} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          </Hidden>
          {/* <Hidden smDown>
            <Sidebar theme={theme} showPerformance={showPerformance} />
          </Hidden> */}
        </nav>

        <div className={`${classes.content} ${isSmallerScreen && classes.contentShift} app-container`}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/about">
                <About />
              </Route>

              {/* <Route exact path="/bond">
                <InnerBond bond={bonds[0]} />
              </Route> */}

              <Route exact path="/">
                <Index />
              </Route>

              <Route exact path="/bond">
                <Bond bonds={bonds} />
              </Route>

              <Route exact path="/calculator">
                <Calculator />
              </Route>

              <Route exact path="/dashboard">
                <Dashboard />
              </Route>

              <Route exact path="/invite">
                <Invite />
              </Route>

              <Route exact path="/invite-detail">
                <InviteDetail />
              </Route>

              <Route exact path="/gc">
                <GC />
              </Route>

              <Route exact path="/stake">
                <Stake />
              </Route>

              <Route exact path="/lock">
                <LockPage />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
        {/* <img src={leftIcon} className="leftIcon"></img>
        <img src={rightIcon} className="rightIcon"></img> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
